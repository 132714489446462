<template>
	<main id="container" class="page-container bg-wave">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="동행뉴스" />
				</div>
			</div>
		</div>
		<div class="page-content news-detail">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title">{{ newsTitle }}</h3>
				</div>
<!--				<div class="news-detail-date">-->
<!--					<span>{{ regDt }}</span>-->
<!--				</div>-->
				<div v-html="newsCn" class="news-detail-image"></div>
				<div class="news-detail-buttons">
					<!-- <button type="button" class="news-detail-button">
						<span>이전</span>
					</button>
					<button type="button" class="news-detail-button">
						<snap>목록</snap>
					</button>
					<button type="button" class="news-detail-button is-active">
						<span>다음</span>
					</button> -->
					<router-link v-if="preId" :to="`/ko/news/${preId}`" class="news-detail-button">이전</router-link>
					<!-- <a href="javascript:void(0)" v-else class="news-detail-button" >이전</a> -->
					<router-link :to="`/ko/news/list`" class="news-detail-button">목록</router-link>
					<router-link v-if="nextId" :to="`/ko/news/${nextId}`" class="news-detail-button">다음</router-link>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_NEWS_INFO } from '@/store/_act_consts';
import { getItems, lengthCheck, getCheckItems, setPaging, tryResCallback, queryToValue, isSuccess, getItem, timestampToDateFormat } from '@/assets/js/utils';

import PageHeader from '@/components/content/PageHeader';
export default {
	name: 'NewsDetail',
	components: { PageHeader },
	data: () => ({
		newsId: 0,
		newsItem: {},
		newsTitle: '',
		newsCn: '',
		regDt: '',
		preId: 0,
		nextId: 0,
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		campnId() {
			return this.campn.campnId;
		},
	},
	watch: {
		$route(to) {
			this.newsId = to.params.newsId;
			this.getNewsInfo();
		},
	},
	created() {
		this.newsId = this.$route.params.newsId;
		this.getNewsInfo();
	},
	mounted() {},
	methods: {
		getNewsInfo() {
			//소상공인컨텐츠 불러오기
			this.$store
				.dispatch(`news/${ACT_GET_NEWS_INFO}`, {
					campnId: this.campnId,
					campnNewsId: this.newsId,
				})
				.then((res) => {
					this.newsItem = getItem(res);
					if (this.newsItem) {
						this.newsTitle = this.newsItem.campnNewsTit;
						this.newsCn = this.newsItem.campnNewsCn;
						this.regDt = this.regDtTimeFormat(this.newsItem.regDt);
						this.preId = this.newsItem.preId;
						this.nextId = this.newsItem.nextId;
					}
				});
		},
		regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy-MM-dd');
		},
	},
};
</script>
